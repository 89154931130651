import { translate } from 'localizations';
import { AccessRights } from 'store/user/user.types';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import { useMemo } from 'react';
import { useAppSelector } from './redux';

/*
    Формирование действий со звонком/звонками в троеточии и в настройках в действиях со звонками

		isSingleCall      - действия для одного звонка или нет
		isSettingsActions - действия на странице настроек
		isHiddenTags      - переключение названия для скрытия/ показа скрытых серых тегов в звонке      
	*/

const useCallsActions = (
	accessRights: AccessRights | null,
	allowedActions: string[] | null | undefined,
	isSingleCall = false,
	isSettingsActions = false,
	isHiddenTags = true,
) => {
	const language = useAppSelector((state) => state.lang.language);
	const exludeCallAction = new Set(['analyze', 'stt_export']);

	const actionsTranslations: Record<string, OptionType> = {
		reset_manual: { value: 'reset_manual', label: translate('resetManual', language) },
		meta_info: { value: 'meta_info', label: translate('metaInfo', language) },
		swap_channels: { value: 'swap_channels', label: translate('swapChannels', language) },
		get_api_tags: { value: 'get_api_tags', label: translate('getApiTags', language) },
		apply_notify_rules: { value: 'apply_notify_rules', label: translate('applyNotifyRules', language) },
		apply_addressbook_tags: {
			value: 'apply_addressbook_tags',
			label: translate('applyAddressbookTags', language),
		},
		editNotificationRule: { value: 'editNotificationRule', label: translate('editNotificationRule', language) },
		stt: { value: 'stt', label: translate('stt', language) },
		analyze: { value: 'analyze', label: translate('applyAnalyze', language) },
		apply_gpt: { value: 'apply_gpt', label: translate('applyGPT', language) },
		tags_filter: { value: 'tags_filter', label: translate('tagsFilter', language) },
		delete: {
			value: 'delete',
			label: isSingleCall ? translate('deleteCall', language) : translate('deleteCalls', language),
		},
		hidden_tags_to_open: { value: 'hidden_tags', label: translate('showHiddenTags', language) },
		hidden_tags_to_close: { value: 'hidden_tags', label: translate('hideHiddenTags', language) },
		call_reload_source_integration: {
			value: 'call_reload_source_integration',
			label: translate('call_reload_source_integration', language),
		},
		handle_continuous_recordings: {
			value: 'handle_continuous_recordings',
			label: translate('handle_continuous_recordings', language),
		},
		restore_continuous_recordings: {
			value: 'restore_continuous_recordings',
			label: translate('restore_continuous_recordings', language),
		},
	};

	const getCallsActions = useMemo((): OptionType[] => {
		if (!accessRights) return [];

		const actions: OptionType[] = [];

		if (accessRights.retag && isSettingsActions) {
			actions.push(actionsTranslations['analyze']);
		}
		if (accessRights.gpt) {
			actions.push(actionsTranslations['apply_gpt']);
		}
		if (accessRights.restt) {
			actions.push(actionsTranslations['stt']);
		}
		if (accessRights.retag) {
			actions.push(actionsTranslations['swap_channels']);
			actions.push(actionsTranslations['get_api_tags']);
		}
		if (accessRights.notify) {
			actions.push(actionsTranslations['apply_notify_rules']);
		}
		if (accessRights.address_book) {
			actions.push(actionsTranslations['apply_addressbook_tags']);
		}
		if (accessRights.tags && !isSettingsActions) {
			actions.push(actionsTranslations['tags_filter']);
		}
		if (accessRights.delete_call) {
			actions.push(actionsTranslations['delete']);
		}
		if (accessRights.call_reload_source_integration) {
			actions.push(actionsTranslations['call_reload_source_integration']);
		}
		if (accessRights.continuous_recordings) {
			actions.push(actionsTranslations['handle_continuous_recordings']);
			actions.push(actionsTranslations['restore_continuous_recordings']);
		}
		return actions;
	}, [accessRights, isSettingsActions, isHiddenTags]);

	const getCallActions = useMemo((): OptionType[] => {
		if (!allowedActions) return [];
		// действия, полученные с бэка
		const callActions = [];
		for (let action of allowedActions) {
			if (actionsTranslations[action] && !exludeCallAction.has(action))
				callActions.push(actionsTranslations[action]);
		}

		// действия, регулируемые на фронте
		isHiddenTags
			? callActions.push(actionsTranslations['hidden_tags_to_open'])
			: callActions.push(actionsTranslations['hidden_tags_to_close']);
		callActions.push(actionsTranslations['meta_info']);
		callActions.push(actionsTranslations['editNotificationRule']);

		return callActions;
	}, [getCallsActions, allowedActions]);

	return { availableActions: isSingleCall ? getCallActions : getCallsActions };
};

export default useCallsActions;
