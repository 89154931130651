import React from 'react';

import CheckListGroup from 'components/shared/CheckListGroup/CheckListGroup';
import { TCallCheckList } from 'store/checkLists/namespaces/responses';
import { BlockBox, DisableMask } from 'components/common';
import { TCheckListGroup, TCheckListItem } from 'components/shared/CheckListGroup/namespace';
import { TUpdateCurrentCallCheckListAnswerById } from 'store/checkLists/namespaces/payloads';
import { CallType } from 'store/calls/calls.types';
import { translate } from 'localizations';
import styles from './callsCheckLists.module.scss';

interface IOwnProps {
	checkLists: TCallCheckList[];
	currentCall: CallType;

	updateCheckList(target: TUpdateCurrentCallCheckListAnswerById): void;
	language: string;
	callCheckListDisabled: boolean;
	canUpdateChecklists: boolean;
}

const CallsCheckLists: React.FC<IOwnProps> = ({
	checkLists,
	updateCheckList,
	currentCall,
	language,
	callCheckListDisabled,
	canUpdateChecklists,
}) => {
	const mappedCheckLists = React.useMemo(
		() =>
			checkLists.map((checkList) => {
				const list: TCheckListGroup & {
					id: string;
					points: number;
					enabled: boolean;
					appraised: boolean;
				} = {
					id: checkList.id,
					label: checkList.checklist.title,
					score: checkList.checklist.maxPoints,
					points: checkList.points,
					enabled: checkList.checklist.enabled,
					appraised: checkList.appraised,
					questions: checkList.checklist.questions.map((question) => ({
						id: question.id,
						title: question.text,
						selectedItem: checkList.answers.find((answ) => answ.question === question.id)?.value ?? null,
						checkList: question.answerValues.map((answer) => ({
							label: answer,
							value: answer ?? null,
						})),
						isManual: checkList.answers.find((answ) => answ.question === question.id)?.isManual ?? false,
					})),
				};

				return list;
			}),
		[checkLists],
	);

	const update = (target: TCheckListItem, checkListIndex: number, questionId: string): void => {
		const checkList = checkLists[checkListIndex];
		updateCheckList({
			answerValue: target.value,
			checkListId: checkList.id,
			questionId,
			callId: currentCall.id?.toString() ?? '',
		});
	};

	return (
		<div className={styles.CallsCheckLists}>
			<DisableMask disable={!canUpdateChecklists}>
				{mappedCheckLists.map((checkList, index) =>
					checkList.questions.length && checkList.enabled ? (
						<BlockBox
							padding="16px"
							key={checkList.id}
							margin={index !== mappedCheckLists.length - 1 ? '0 0 16px 0' : '0'}
						>
							<CheckListGroup
								questions={checkList.questions}
								label={checkList.label}
								points={checkList.points}
								score={checkList.score}
								appraised={checkList.appraised}
								onClickCheckListItem={(target, questionId): void => {
									update(target, index, questionId);
								}}
								language={language}
								callCheckListDisabled={callCheckListDisabled}
							/>
						</BlockBox>
					) : (
						<>
							{!checkList.questions.length && checkList.enabled && checkList.appraised && (
								<div className={styles.chekListAppraised} key={checkList.id}>
									{translate('checklists_appraised', language)}
								</div>
							)}
						</>
					),
				)}
			</DisableMask>
		</div>
	);
};

export default CallsCheckLists;
