import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from 'api/api.config';

export const consumptionHistoryApi = createApi({
	reducerPath: 'consumptionHistoryApi',
	baseQuery: baseQueryWithReauth,
	refetchOnMountOrArgChange: true,
	endpoints: (build) => ({
		getCallsHistory: build.query<
			any,
			{ userId: string; startDate: string; endDate: string; format?: 'json' | 'excel'; filter?: string }
		>({
			query: ({ userId, startDate, endDate, format = 'json', filter }) => ({
				url: `/user/${userId}/history/calls`,
				params: {
					start_date: startDate,
					end_date: endDate,
					format,
					...(filter && { filter }), // Добавляем filter только если он есть
				},
				responseHandler: format === 'excel' ? (response) => response.blob() : undefined,
			}),
		}),
		getChatHistory: build.query<
			any,
			{ userId: string; startDate: string; endDate: string; format?: 'json' | 'excel'; filter?: string }
		>({
			query: ({ userId, startDate, endDate, format = 'json', filter }) => ({
				url: `/user/${userId}/history/chats`,
				params: {
					start_date: startDate,
					end_date: endDate,
					format,
					...(filter && { filter }),
				},
				responseHandler: format === 'excel' ? (response) => response.blob() : undefined,
			}),
		}),
		getGPTHistory: build.query<
			any,
			{ userId: string; startDate: string; endDate: string; format?: 'json' | 'excel'; filter?: string }
		>({
			query: ({ userId, startDate, endDate, format = 'json', filter }) => ({
				url: `/user/${userId}/history/gpt`,
				params: {
					start_date: startDate,
					end_date: endDate,
					format,
					...(filter && { filter }),
				},
				responseHandler: format === 'excel' ? (response) => response.blob() : undefined,
			}),
		}),
	}),
});

export const {
	useGetCallsHistoryQuery,
	useGetChatHistoryQuery,
	useGetGPTHistoryQuery,
	useLazyGetCallsHistoryQuery,
	useLazyGetChatHistoryQuery,
	useLazyGetGPTHistoryQuery,
} = consumptionHistoryApi;
