import { Ellipsis } from 'components/Icons/Ellipsis';
import { LabelSelect } from 'components/common';
import { OptionType } from 'components/common/Selects/LabelSelect/LabelSelect';
import { translate } from 'localizations';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { SttValuesForRequest } from 'widgets/STT/namespaces';
import { FC, useEffect, useState } from 'react';
import { LangType } from 'store/lang/lang.slice';
import { AccessRights, UserType } from 'store/user/user.types';
import useCallsActions from 'hooks/useCallsActions';
import { useToggleOpen } from 'hooks';
import { useHistory, useParams } from 'react-router-dom';
import { RouteParams } from 'pages/Settings/types';
import { ModalWindow, IconButton } from 'shared/ui';
import { getCallMetaInfo } from 'store/calls/calls.slice';
import { selectCurrentCall } from 'store/calls/selectors';
import MetaInfoModalInner from './components/MetaInfoModalInner/MetaInfoModalInner';
import DeleteModalInner from './components/DeleteModalInner/DeleteModalInner';
import SttModalInner from './components/SttModalInner/SttModalInner';
import TagsFilterModalInner from './components/TagsFilterModalInner/TagsFilterModalInner';
import ManualModal from './components/ManualModalInner/ManualModal';
import { OnActionClickOptions } from '../../hooks/useActionsFunctions';

interface ActionsSelectProps {
	accessRights: AccessRights | null;
	language: LangType | string;
	pageCount: number | null;
	onIconClick: (params: OnActionClickOptions) => void;
	onIconClickSTT: (values: SttValuesForRequest) => void;
	userInfo: UserType | null;
	isSingleCall?: boolean;
	callsCount?: number;
	callId?: string;
	setLang?: (lang: { value: string; label: string } | null) => void;
	isHiddenTags?: boolean;
	onActionHideTags?: () => void;
	callAllowedActions?: string[];
}

const ActionsSelect: FC<ActionsSelectProps> = ({
	accessRights,
	language,
	pageCount,
	onIconClick,
	onIconClickSTT,
	userInfo,
	isSingleCall,
	callsCount,
	callId,
	setLang,
	isHiddenTags,
	onActionHideTags,
	callAllowedActions,
}) => {
	const history = useHistory();
	const { userId } = useParams<RouteParams>();
	const dispatch = useAppDispatch();
	const currentCall = useAppSelector(selectCurrentCall);

	const { availableActions } = useCallsActions(accessRights, callAllowedActions, isSingleCall, false, isHiddenTags);

	const [downloadingLoading, setDownloadingLoading] = useState<boolean>(false);
	const [metaInfo, setMetaInfo] = useState([]);
	const metaInfoIsLoading = useAppSelector((state) => state.calls.metaInfoIsLoading);

	// stt modal
	const { toggle: toggleModalSTT, isShowing: isOpenModalSTT } = useToggleOpen();
	// delete modal
	const { toggle: toggleModalDelete, isShowing: isOpenModalDelete } = useToggleOpen();
	// MetaInfo modal
	const { toggle: toggleModalMetaInfo, isShowing: isOpenModalMetaInfo } = useToggleOpen();
	// Е
	const { toggle: toggleModalTagsFilter, isShowing: isOpenModalTagsFilter } = useToggleOpen();
	// tag manual modal
	const { toggle: toggleModalManual, isShowing: isOpenModalManual } = useToggleOpen();

	useEffect(() => {
		const fetchCallMetaInfo = async () => {
			if (isOpenModalMetaInfo && currentCall && currentCall.id) {
				const res = await dispatch(getCallMetaInfo(currentCall.id));
				setMetaInfo(res.payload);
			}
		};

		fetchCallMetaInfo();
	}, [isOpenModalMetaInfo, currentCall]);

	const textForSTT = isSingleCall
		? translate('setLangEngineModel', language)
		: translate('areSureSttCalls', language);
	const textForDelete = isSingleCall
		? translate('areSureDeleteCall', language)
		: `${translate('areSureDeleteCalls', language)} ${translate('allFoundCalls', language)} ${callsCount})`;
	const textForMetaInfo = translate('metaInfoTitle', language);
	const TagsFilter = translate('tagsFilter', language);

	return (
		<div>
			{availableActions.length > 0 && (
				<>
					<LabelSelect
						notPlusMinus
						isSingle
						isDisable={downloadingLoading || pageCount === 0}
						closeMenuSelect
						valueHandler={async (value: OptionType) => {
							const action = value.value;
							setDownloadingLoading(true);

							switch (action) {
								case 'stt':
									toggleModalSTT();
									break;
								case 'delete':
									toggleModalDelete();
									break;
								case 'editNotificationRule':
									history.push(
										`/${language}/${userId}/notifications/create-rule-from-call/${callId}`,
									);
									break;
								case 'meta_info':
									toggleModalMetaInfo();
									break;
								case 'tags_filter':
									toggleModalTagsFilter();
									break;
								case 'reset_manual':
									toggleModalManual();
									break;
								case 'hidden_tags':
									onActionHideTags?.();
									break;
								default:
									onIconClick({ action });
							}

							setDownloadingLoading(false);
						}}
						value={[]}
						options={availableActions}
						customLabel={
							<IconButton
								mainLoading={downloadingLoading}
								disabled={downloadingLoading || pageCount === 0}
								backgroundColor="#F8FAFC"
								icon={<Ellipsis />}
								margin={isSingleCall ? '0 0 0 8px' : '0 0 0 16px'}
								testId="calls_actions_actions-btn"
							/>
						}
					/>

					<ModalWindow
						isMWOpen={isOpenModalSTT}
						handleMWClose={toggleModalSTT}
						text={textForSTT}
						width="600px"
						overflow="auto"
						overflowMW="auto"
					>
						<SttModalInner
							language={language}
							isSingleCall={isSingleCall || false}
							callsCount={callsCount || 0}
							userInfo={userInfo}
							toggleModalSTT={toggleModalSTT}
							onIconClickSTT={onIconClickSTT}
							setLang={setLang}
						/>
					</ModalWindow>

					<ModalWindow isMWOpen={isOpenModalDelete} handleMWClose={toggleModalDelete} text={textForDelete}>
						<DeleteModalInner
							toggleModalDelete={toggleModalDelete}
							onIconClick={onIconClick}
							language={language}
						/>
					</ModalWindow>

					<ModalWindow
						isMWOpen={isOpenModalMetaInfo}
						handleMWClose={toggleModalMetaInfo}
						text={textForMetaInfo}
						width="600px"
					>
						<MetaInfoModalInner metaInfo={metaInfo} isLoading={metaInfoIsLoading} />
					</ModalWindow>

					<ModalWindow
						isMWOpen={isOpenModalTagsFilter}
						handleMWClose={toggleModalTagsFilter}
						text={TagsFilter}
						width="600px"
						overflow="auto"
						overflowMW="auto"
					>
						<TagsFilterModalInner toggleModalTagsFilter={toggleModalTagsFilter} />
					</ModalWindow>
					{isSingleCall && callId && (
						<ManualModal
							language={language}
							callId={callId}
							toggleModalManual={toggleModalManual}
							isOpenModalManual={isOpenModalManual}
						/>
					)}
				</>
			)}
		</div>
	);
};

export default ActionsSelect;
