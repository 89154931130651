import { LangType } from '../lang/lang.slice';
import { SearchItem } from '../search/namespaces/responses';

export type UserType = {
	id: string;
	login: string;
	role: 'admin' | 'user' | 'operator' | 'manager' | '';
	timezone: string;
	language: LangType;
	comment: string;
	name: string;
	email: string;
	phoneNumber: string;
	parentUser?: string;
	accessRights?: string[];
	searchTags?: string[];
	searchHideTags?: string[];
	operatorTags?: string[];
	clientTags?: string[];
	fastManualTags?: string[];

	useBilling?: boolean | null;

	lanbillingInfo?: {
		user_id?: string;
	} | null;

	sttOptions: {
		sttEngine?: string;
		sttEconomize?: boolean;

		sttOptionsMap: {
			language: string;
			model: string;
			count_per_iteration?: number;
		};
	};

	// TODO: уточнить, возможно, эти поля уже неактуальны и будут только внутри sttOptionsMap
	sttLanguage?: string;
	sttModel?: string;

	settings?: boolean;
	quotaRemindTime?: number;
	quotaTotalTime?: number;
	industry?: string | undefined;
	partner?: string | undefined;
	password?: string;
	toManyValuesExcludeTags?: string[];
	sttExportHeaderTags?: string[];
	gptQuotas: {
		chat_gpt: string | number | undefined;
		yandex_gpt: string | number | undefined;
	};
	gptQuotasRest: {
		chat_gpt: string | number | undefined;
		yandex_gpt: string | number | undefined;
	};
	error?: string;
};

export type ChildUserType = {
	id: string;
	role: string;
	login: string;
	name: string;
	timezone: string;
	parentUser?: string;
	quotaRemindTime?: number;
	quotaTotalTime?: number;
	industry?: string | undefined;
	partner?: string | undefined;
};

export type AccessRights = {
	search: boolean; // COMPLETE
	audio: boolean; // COMPLETE
	stt: boolean; // COMPLETE
	upload: boolean; // COMPLETE
	report: boolean; // COMPLETE
	report_manage: boolean;
	dictionary: boolean; // this
	tagging: boolean; // this
	notify: boolean; // LATER
	address_book: boolean; // LATER
	phone_number: boolean; // LATER
	tags: boolean; // LATER
	export: boolean;
	retag: boolean;
	restt: boolean;
	calls_actions: boolean;
	set_filter_user: boolean;
	add_user: boolean;
	add_operator: boolean;
	set_default_engine: boolean;
	admin: boolean;
	checklist: boolean;
	checklist_view: boolean;
	checklist_update: boolean;
	integration_view: boolean;
	integration_edit: boolean;
	delete_call: boolean;
	delete_user: boolean;
	delete_operator: boolean;
	import_foreign_rules: boolean;
	quota_view: boolean;
	quota_edit: boolean;
	gpt: boolean;
	manual_tagging: boolean;
	gpt_quota: boolean;
	usage_history: boolean;
	user_modules_setup: boolean;
	billing_view: boolean;
	enable_free_gpt_rule: boolean;
	video: boolean;
	deals: boolean;
	continuous_recordings: boolean;
	call_reload_source_integration: boolean;
};

export enum AuthResponseErrors {
	FieldsRequired = 422,
	IncorrectData = 401,
}

export type QuotasType = {
	id: string;
	current: boolean;
	datetime_from: string | null;
	datetime_to: string | null;
	finished: boolean;
	given_by: string;
	given_by_login: string;
	owner: string;
	set_date: string;
	time_count: number;
	rest_time: number;
	time_nominal: number;

	idNumber?: number | string;
};

export interface AccessRightType {
	key: string;
	title: string;
}

export interface ManagersListType extends AccessRightType {
	isActive: boolean;
}

export interface ReportLimit {
	id: string;
	name: string;
}

export interface CallsLimitFilter {
	id: string;
	title: string | null;
	items: SearchItem[];
	isNew?: boolean;
}

export interface IPartnerIds {
	id: string;
	role: string;
	login: string;
	name: string;
	comment?: string;
	createdBy?: string;
	timezone?: string;
}
